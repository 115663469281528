<template>
	<div >
		
		<div class="ft20 cl-black cl-main ftw500">自定义合伙人</div>
		
		<div class="mt20">
			<div class="bg-w pd30" style="min-height: 600px;">
				<div class="flex alcenter">
					<a-button type="primary" icon="plus" @click="addCustomAct()">添加合伙人</a-button>
					<div class="ml30">
						<a-radio-group v-model="search.status" button-style="solid" @change="changeStatus">
							<a-radio-button :value="1">
							  现有合伙人
							</a-radio-button>
							<a-radio-button :value="-1">
							  历史合伙人
							</a-radio-button>
						</a-radio-group>
					</div>
				</div>
				
				<div class="mt10">
					<div class="form-search-box ">
						<a-form layout="inline">
							<a-form-item label="请输入合伙人手机号">
								<a-input v-model="search.mobile" placeholder="请输入合伙人手机号"></a-input>
							</a-form-item>
							
							<a-form-item>
								<a-button @click="searchAct" type="primary">查询</a-button>
								<a-button @click="cancelAct" class="ml10">取消</a-button>
							</a-form-item>
						</a-form>
					</div>
				</div>
				
				
				
				<div class="mt30">
					<div class="wxb-table-gray">
						<a-table rowKey="partner_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
						 :loading="loading">
							<div class="flex alcenter center" slot="expire_time" slot-scope="expire_time,record">
								<div>{{expire_time}}</div>
								<div v-if="record.is_expire==1" class="cl-price">(已到期)</div>
							</div>
							
							
							
							<div class="flex alcenter center" slot="custom_rate" slot-scope="custom_rate,record">
								{{custom_rate}}%
							</div>
							
							<div class="flex alcenter center" slot="profit" slot-scope="profit,record">
								{{profit}}元
							</div>
					
							<template slot="action" slot-scope="record">
								<div class="flex center">
									<a-dropdown placement="bottomRight">
										<span class="more-act">
											<i class="iconfont iconmore_gray"></i>
										</span>
										<a-menu slot="overlay" v-if="record.status ==1">
											  <a-menu-item>
												<a class="menu-act" href="javascript:;"  @click="toDetail(record)">
													<i class="iconfont ft14 iconsee"></i>
													<span class="ml10">查看</span>
												</a>
											  </a-menu-item>
											  <a-menu-item>
													<a class="menu-act" href="javascript:;"  @click="editCustomAct(record)">
														<i class="iconfont ft14 iconedit"></i>
														<span class="ml10">编辑</span>
													</a>
											  </a-menu-item>
											  <a-menu-item>
													<a class="menu-act" href="javascript:;" @click="delayCustomAct(record)">
														<i class="iconfont ft14 iconpartner_delaytime"></i>
														<span class="ml10">延期</span>
													</a>
											  </a-menu-item>
											  <a-menu-item>
													<a class="menu-act" href="javascript:;" @click="withdrawal(record)">
														<i class="iconfont ft14 iconpartner_withdrawal"></i>
														<span class="ml10">退股</span>
													</a>
											  </a-menu-item>
										</a-menu>
										
										<a-menu slot="overlay" v-if="record.status == -1">
											  <a-menu-item>
												<a class="menu-act" href="javascript:;"  @click="toDetail(record)">
													<i class="iconfont ft14 iconsee"></i>
													<span class="ml10">查看</span>
												</a>
											  </a-menu-item>
											  <a-menu-item>
													<a class="menu-act" href="javascript:;" @click="del(record)">
														<i class="iconfont ft14 icondelete"></i>
														<span class="ml10">删除</span>
													</a>
											  </a-menu-item>
										</a-menu>
									  </a-dropdown>
								</div>
							</template>
						</a-table>
					</div>
				</div>
			
				<div v-if="editCustomLoading">
					<edit-Custom :partner_id="partner_id" :visible="editCustomLoading" @cancel="cancelEditCustom" @ok="okEditCustom"></edit-Custom>
				</div>
				<div v-if="delayCustomLoading">
					<delay-Custom :partner_id="partner_id" :visible="delayCustomLoading" @cancel="cancelDelayCustom" @ok="okDelayCustom"></delay-Custom>
				</div>
			</div>
		</div>
	</div>
				
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	import editCustom from './components/Custom/modal/editCustom.vue';
	import delayCustom from './components/Custom/modal/delay.vue';
	export default {
		mixins: [listMixin],
		components:{
			editCustom,
			delayCustom,
		},
		data() {
			return {
				loading: false,
				editCustomLoading:false,
				delayCustomLoading:false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				partner_id:0,
				search: {
					mobile: '',
					status:1,
				},
				columns: [
					{title: '合伙人姓名',dataIndex: 'name',align: 'center',ellipsis: true},
					{title: '手机号',dataIndex: 'mobile',align: 'center',ellipsis: true},
					{title: '添加时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '到期时间',dataIndex: 'expire_time',align: 'center',scopedSlots: {customRender: 'expire_time'}},
					{title: '自定义名称',dataIndex: 'custom_name',align: 'center',ellipsis: true},
					{title: '自定义比例',dataIndex: 'custom_rate',align: 'center',scopedSlots: {customRender: 'custom_rate'}},
					{title: '分红收益',dataIndex: 'profit',align: 'center',scopedSlots: {customRender: 'profit'}},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}},
				],
				datas: [],
			}
		},
		methods: {
			
			getLists() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/getPartnerList', {
					limit: this.pagination.pageSize,
					page: this.pagination.current,
					mobile: this.search.mobile,
					status: this.search.status,
					type:4,
				}).then(res => {
					this.pagination.total = res.total;
					this.datas = res.list;
					this.loading = false;
				}).catch(res => {
					console.log(res);
					this.loading = false;
				})
			},
			
			withdrawal(record){
				this.$confirm({
					title:'确认这个合伙人要退股吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/withdrawalPartner',{
								partner_id:record.partner_id,
							}).then(res=>{
								this.$message.success('退股成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			del(record){
				this.$confirm({
					title:'确认删除这个合伙人吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/delPartner',{
								partner_id:record.partner_id,
							}).then(res=>{
								this.$message.success('删除成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			changeStatus() {
				this.getLists();
			},
			
			
			addCustomAct(){
				this.partner_id=0;
				this.editCustomLoading=true;
			},
			editCustomAct(record){
				this.partner_id=record.partner_id;
				this.editCustomLoading=true;
			},
			cancelEditCustom(){
				this.editCustomLoading=false;
			},
			okEditCustom(){
				this.editCustomLoading=false;
				this.getLists();
			},
			
			delayCustomAct(record){
				this.partner_id=record.partner_id;
				this.delayCustomLoading=true;
			},
			cancelDelayCustom(){
				this.delayCustomLoading=false;
			},
			okDelayCustom(){
				this.delayCustomLoading=false;
				this.getLists();
			},
			
			
			toDetail(record){
				this.$router.push('/partner/custom/detail?id='+record.partner_id)
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
		}
	}
</script>

<style>
	
</style>
