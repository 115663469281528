<template>
	<div>
		<a-modal :title="getTitle" :width="540" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					保存
				</a-button>
			</template>
			<a-spin :spinning="loading">
				<div>
					<div class="flex  mt10">
						<a-input class="woyaou-input " prefix="手机号" style="width: 230px;" placeholder="请输入手机号" v-model="form.mobile" :maxLength="20">
						</a-input>
						<a-input class="woyaou-input ml20" style="width: 230px;" prefix="股东姓名" placeholder="请输入股东姓名" v-model="form.name" :maxLength="20">
						</a-input>
					</div>
					
					<div class="mt20">自定义名称</div>
					<div class="mt10">
					  <a-input  style="width: 400px;"  placeholder="请输入自定义名称" v-model="form.custom_name" :maxLength="20"/>
					</div>
					
					<div class="mt20">自定义比例</div>
					<div class="mt10 flex alcenter">
					  <a-input-number :min="0" :precision="2" :max="100" style="width: 200px;"  
					  placeholder="请输入自定义比例" v-model="form.custom_rate" />
					  <div class="ml8">%</div>
					</div>
					
					<div class="mt20">有效期至</div>
					<div class="mt10">
					  <a-date-picker  valueFormat="YYYY-MM-DD" v-model="form.expire_time"/>
					</div>
					
					
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			partner_id:{
				type:Number,
				default:0,
			}
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				form: {
					name:'',
					mobile:'',
					custom_name:'',
					custom_rate:'',
					expire_time:'',
				}
			}
		},
		created() {
			this.loaddata();
		},
		computed:{
			getTitle(){
				if(this.partner_id==0){
					return '添加股东';
				}else{
					return '编辑股东';
				}
			}
		},
		methods: {
			
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showPartner',{
					partner_id:this.partner_id
				}).then(res=>{
					this.loading=false;
					if(res.detail){
						this.form=res.detail;
					}
				}).catch(res=>{
					console.log(res);
					this.loading=false;
					this.$emit("cancel");
				})
			},
			
			
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/savePartner',{
					partner_id:this.partner_id,
					name:this.form.name,
					mobile:this.form.mobile,
					expire_time:this.form.expire_time,
					custom_name:this.form.custom_name,
					custom_rate:this.form.custom_rate,
					type:4,
				}).then(res=>{
					this.$message.success('保存成功');
					this.$emit("ok");
					this.confirmLoading=false;
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
				
			},
			
		}
	}
</script>

<style>
	.share_capital_item{
		padding: 4px 16px;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		font-size: 16px;
		font-weight: 500;
		color: #232529;
		margin-right: 10px;
	}
	
	.share_capital_item.active{
		padding: 4px 16px;
		background: #FFFFFF;
		border: 1px solid #4772FF;
		font-size: 16px;
		font-weight: 500;
		color: #4772FF;
		margin-right: 10px;
	}
	
</style>
